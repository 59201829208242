import React, { useState } from "react";
import { Row, Col, Modal, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "./dashboard.module.scss";
import { Link } from "react-router-dom";
import Links from "./Links";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useSelector } from "react-redux";
import proPic from '../../../assets/images/nophoto/no_photo.jpg';
import QRCode from "react-qr-code";
import { Padding } from "@mui/icons-material";
// import QRCode from 'qrcode'

const Profiles = React.memo((props) => {


  const [showQRCode, setShowQRCode] = useState(false);
  const handleQRCodeClose = () => setShowQRCode(false);
  const handleQRCodeShow = () => setShowQRCode(true);

  const qrCodeSize = 256;
  // const qrCodeImage = QRCode.toDataURL(qrCodeData, { width: qrCodeSize, height: qrCodeSize });
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  return (
    <>
      <div
        className={`${currentTheme == "theme-dark"
          ? style.dark_mode_dashboard_pannel_box
          : style.dashboard_pannel_box
          }`}
      >
        {props.lodervalue && (
          <Row>
            <Col xl={4} lg={4} md={12}>
              <div className={style.profile_main_sec}>
                <div className={style.profile_main_img_bx}>
                  <SkeletonTheme baseColor="#FF5733" highlightColor="#444">
                    <Skeleton circle={true} height={90} width={90} />
                  </SkeletonTheme>
                </div>
                <div className={style.profile_main_txt_sc}>
                  <div>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton width={170} count={1} />
                    </SkeletonTheme>
                  </div>
                  <div>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton width={100} count={1} />
                    </SkeletonTheme>
                  </div>
                  <div>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      <Skeleton
                        width={120}
                        height={30}
                        count={1}
                        borderRadius={50}
                      />
                    </SkeletonTheme>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={4} lg={4} md={12}>
              <div className={style.profile_soc_share_sec}>
                <div className={style.profilesoc_share_bx_hd}>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton width={100} />
                  </SkeletonTheme>
                </div>
                <div className={style.profilesoc_share_bx_row}>
                  <div className={style.profilesoc_share_bx_ico}>
                    <SkeletonTheme baseColor="#202020" highlightColor="#444">
                      {[1, 2, 3, 4].map((items, index) => {
                        return (
                          <span
                            style={{ paddingLeft: "3px", paddingTop: "10px" }}
                          >
                            <Skeleton circle={true} height={30} width={30} />
                          </span>
                        );
                      })}
                    </SkeletonTheme>
                  </div>
                </div>
              </div>
            </Col>
            <Col xl={2} lg={2} md={6}>
              <div className={style.profile_other_box}>
                <div className={style.profile_other_box_cnt}>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton width={100} />
                  </SkeletonTheme>
                </div>
                <div className={style.profile_other_box_cnt}>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton width={100} />
                  </SkeletonTheme>
                </div>
              </div>
            </Col>
            <Col xl={2} lg={2} md={6}>
              <div className={`${style.profile_other_box} border-0`}>
                <div className={style.profile_other_box_cnt}>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton width={100} />
                  </SkeletonTheme>
                </div>
                <div className={style.profile_other_box_cnt}>
                  <SkeletonTheme baseColor="#202020" highlightColor="#444">
                    <Skeleton width={100} />
                  </SkeletonTheme>
                </div>
              </div>
            </Col>
          </Row>
        )}
        {props.profile && (
          <Row>
            <Col xl={4} lg={4} md={12}>
              <div className={style.profile_main_sec}>
                <div className={style.profile_main_img_bx}>
                  <img src={props.profile.user_photo ? props.profile.user_photo : proPic} alt="No Image" />
                </div>
                <div
                  className={`${currentTheme == "theme-dark"
                    ? style.dark_mode_profile_main_txt_sc
                    : style.profile_main_txt_sc
                    }`}
                >
                  <strong>{props.profile.full_name} </strong>
                  <span>{props.profile.user_name}</span>
                  <strong><span>{props.profile.ref_id}</span></strong>
                  <Link
                    to="/profile"
                    className={`${currentTheme == "theme-dark"
                      ? style.dark_mode_view_prfl_btn
                      : style.view_prfl_btn
                      }`}
                  >
                    {t("Dashboard.viewProfile")}
                  </Link>
                </div>
              </div>
            </Col>
            {(props.profile.replica || props.profile.lead_capture) && (
              <Col xl={2} lg={2} md={6}>
                <div className={style.profile_soc_share_sec}>
                  {props.profile.replica && (
                    <div className={style.profilesoc_share_bx}>
                      <div
                        className={`${currentTheme == "theme-dark"
                          ? style.dark_mode_profilesoc_share_bx_hd
                          : style.profilesoc_share_bx_hd
                          }`}
                      >
                        {t("Dashboard.replicaLinks")}
                      </div>
                      <div
                        className={`${currentTheme == "theme-dark"
                          ? style.dark_mode_profilesoc_share_bx_row
                          : style.profilesoc_share_bx_row
                          }`}
                      >
                        {props.profile.replica.map((link, index) => {
                          return (
                            <div
                              className={`${currentTheme == "theme-dark" &&
                                `${style.dark_mode_profilesoc_share_bx_ico}`
                                }`}
                            >
                              <Links
                                iscopy={
                                  link.icon === "fa fa-files-o" ? true : false
                                }
                                icon={link.icon}
                                key={index}
                                link={link.link}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                  {props.profile.lead_capture && (
                    <div className={style.profilesoc_share_bx}>
                      <div
                        className={`${currentTheme == "theme-dark"
                          ? style.dark_mode_profilesoc_share_bx_hd
                          : style.profilesoc_share_bx_hd
                          }`}
                      >
                        {t("Dashboard.leadCapture")}
                      </div>
                      <div
                        className={`${currentTheme == "theme-dark"
                          ? style.dark_mode_profilesoc_share_bx_row
                          : style.profilesoc_share_bx_row
                          }`}
                      >
                        {props.profile.lead_capture.map((link, index) => {
                          return (
                            <div
                              className={`${currentTheme == "theme-dark" &&
                                `${style.dark_mode_profilesoc_share_bx_ico}`
                                }`}
                            >
                              <Links
                                iscopy={
                                  link.icon === "fa fa-files-o" ? true : false
                                }
                                icon={link.icon}
                                key={index}
                                link={link.link}
                              />
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </Col>
            )}
            {props.profile.replica[0].link && (
              <Col xl={2} lg={2} md={6}>
                <div className={style.profile_soc_share_sec}>
                  <div className={style.profilesoc_share_bx}>
                    <div
                      className={`${currentTheme == "theme-dark"
                        ? style.dark_mode_profilesoc_share_bx_hd
                        : style.profilesoc_share_bx_hd
                        }`}
                    >
                      {t("Dashboard.qr_code")}
                    </div>
                    <div
                      className={`${currentTheme == "theme-dark"
                        ? style.dark_mode_profilesoc_share_bx_row
                        : style.profilesoc_share_bx_row
                        }`}
                      onClick={handleQRCodeShow}
                    >
                      <div className={style.qr_code}>
                      <QRCode
                        size={256}
                        style={{ height: "100%", maxWidth: "100%", width: "100%" }}
                        value={props.profile.replica[0].link}
                        viewBox={`0 0 256 256`}
                      />
                      </div>
                      
                    </div>
                  </div>

                  <div style={{ paddingRight: "20px" }}>
                    {/* <QRCode
                        size={256}
                        style={{ height: "50%", maxWidth: "50%", width: "100%" }}
                        value={props.profile.replica[0].link}
                        viewBox={`0 0 256 256`}
                      /> */}
                  </div>
                </div>
              </Col>
            )}
            {(props.profile.membership_package || props.profile.rank) && (
              <Col xl={2} lg={2} md={6}>
                <div
                  className={`${currentTheme == "theme-dark"
                    ? style.dark_mode_profile_other_box
                    : style.profile_other_box
                    }`}
                >
                  {props.profile.membership_package && (
                    <div
                      className={`${currentTheme == "theme-dark"
                        ? style.dark_mode_profilesoc_share_bx_hd
                        : style.profilesoc_share_bx_hd
                        }`}
                    >
                      <span>
                        {t("Dashboard.membershipPackage")}
                        <br />
                        <strong>
                          {props.profile.membership_package.name ?? "NA"}{" "}
                        </strong>
                      </span>
                    </div>
                  )}
                  {props.profile.rank && (
                    <div className={style.profile_other_box_cnt}>
                      <span>
                        {t("Common.rank").toUpperCase()}
                        <br />
                        <strong>{props.profile.rank.curent_rank}</strong>
                      </span>
                    </div>
                  )}
                </div>
              </Col>
            )}
            <Col xl={2} lg={2} md={6}>
              <div
                className={`${currentTheme == "theme-dark"
                  ? style.dark_mode_profile_other_box
                  : style.profile_other_box
                  }`}
              >
                <div className={style.profile_other_box_cnt}>
                  <span>
                    {t("Common.placement")}:
                    <br />
                    <strong>{props.profile.placement}</strong>
                  </span>
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
      <Modal show={showQRCode} onHide={handleQRCodeClose}>
        <Modal.Header closeButton >
          <Modal.Title style={{color:"white"}}>QR Code</Modal.Title>
        </Modal.Header>
        <Modal.Body >
          {/* <div style={{alignItems:'center'}}><p>scan QR code for registartion</p></div> */}
          {/* <img src="" alt="QR Code" style={{ width: qrCodeSize, height: qrCodeSize }} /> */}
          <div style={{ background: "white", padding: "20px" }}>
            <QRCode
              size={256}
              style={{ height: "100%", maxWidth: "100%", width: "100%" }}
              value={props.profile.replica[0].link}
              viewBox={`0 0 256 256`}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {/* <Button variant="secondary" onClick={handleQRCodeClose}>
            Close
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
});
export default Profiles;
