import React, { useState, useEffect } from "react";
import ItemsCarousel from "react-items-carousel";
import { Button } from "react-bootstrap";
import BoardServices from "../../../service/board/board";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Spinner from "../../shared/Spinner";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import ArrowCircleLeftTwoToneIcon from "@mui/icons-material/ArrowCircleLeftTwoTone";
import ArrowCircleRightTwoToneIcon from "@mui/icons-material/ArrowCircleRightTwoTone";
import style from "./board.module.scss";
import { useTranslation } from "react-i18next";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";

const Content = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const Service = new BoardServices();
  const [state, setState] = useState({
    children: [],
    activeItemIndex: 0,
    searchKey: "",
    start: 0,
    length: 10,
    board_id: id,
    tiles: [],
    loading: false,
    dataFetchCompleteStatus: false,
    numberOfCards: 4,
    nodata: false,
  });
  console.log("state ==== ", state.children);

  const getAPIResult = async (data) => {
    let result = await Service.getBoardDetails(data);
    return result.data;
  };
  const getData = async (data, type) => {
    // setState({ ...state, activeItemIndex: 0 });
    setState((prev) => ({
      ...prev,
      loading: true,
      activeItemIndex: 0,
    }));
    let result = await Service.getBoardDetails(data);
    if (result.data.length <= 0) {
      setState({ ...state, nodata: true });
      if (type != "search") {
        setState((prev) => ({
          ...prev,
          dataFetchCompleteStatus: true,
        }));
      }
    } else {
      setState({ ...state, nodata: false });
    }
    setState((prev) => ({
      ...prev,
      loading: false,
    }));
    setState((prev) => ({
      ...prev,
      tiles: result.data,
      children: createChildren(result.data),
      searchKey: type == "reset" ? "" : state.searchKey,
    }));
    return result.data;
  };
  useEffect(() => {
    let data = {
      start: 0,
      length: 10,
      board_id: id,
      username: "",
    };
    setState({ ...state, loading: true });
    getAPIResult(data).then((result) => {
      if (result.length <= 0) {
        setState({
          ...state,
          nodata: true,
          dataFetchCompleteStatus: true,
          loading: false,
          activeItemIndex: 0,
          start: 0,
          length: 10,
        });
      } else {
        // setState({
        //   ...state,
        //   tiles: result,
        //   children: createChildren(result),
        //   loading: false,
        //   dataFetchCompleteStatus: false,
        //   activeItemIndex: 0,
        //   start: 0,
        //   length: 10,
        // });
        setState((prev) => ({
          ...prev,
          tiles: result,
          children: createChildren(result),
          loading: false,
          dataFetchCompleteStatus: false,
          activeItemIndex: 0,
          start: 0,
          length: 10,
          nodata: false,
        }));
      }
    });
  }, [id]);
  useEffect(() => {
    if (
      state.tiles.length - Number(state.activeItemIndex) == 5 &&
      Number(state.activeItemIndex) != 0 &&
      !state.dataFetchCompleteStatus
    ) {
      updateTiles();
    }
  }, [state.activeItemIndex]);
  const updateNoOfCards = async () => {
    let numberOfCards = 4;

    let width = window.innerWidth;
    if (width >= 1200) {
      numberOfCards = 4;
    } else if (width < 1200 && width >= 800) {
      numberOfCards = 3;
    } else if (width >= 600) {
      numberOfCards = 2;
    } else {
      numberOfCards = 1;
    }
    setState((prev) => ({
      ...prev,
      numberOfCards: numberOfCards,
    }));
  };
  const updateTiles = async () => {
    let { tiles, start, length } = state;
    start = start + length;
    let filters = {
      start: start,
      length: length,
      board_id: id,
      username: "",
    };
    let result = await Service.getBoardDetails(filters);
    if (result.data.length <= 0) {
      setState((prev) => ({
        ...prev,
        dataFetchCompleteStatus: true,
      }));
    }
    result.data.map((item) => {
      tiles.push(item);
    });
    setState((prev) => ({
      ...prev,
      tiles: tiles,
      start: start,
      length: length,
      children: createChildren(tiles),
    }));
  };
  const createChildren = (data) =>
    data.map((item, index) => (
      <div className="coupon">
        <div className="left">
          <div style={{ paddingTop: "20px" }}>{`${t("Common.ticket")}:${
            Number(index) + 1
          }`}</div>
        </div>
        <div className="center">
          <div>
            <h2>{item.username}</h2>
            <h3>{item.ticket}</h3>
            {/* <small>Valid until May, 2023</small> */}
          </div>
        </div>

        <div className="right">
          <div style={{ paddingRight: "20px", transform: "rotate(10deg)" }}>
            {item.board_complete == "yes" ? (
              <CheckCircleOutlinedIcon sx={{ fontSize: 40 }} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    ));

  const changeActiveItem = (activeItemIndex) => {
    setState({ ...state, activeItemIndex: activeItemIndex, searchKey: "" });
  };

  const submitHamdler = (e) => {
    let data = {
      start: 0,
      length: 10,
      board_id: id,
      username: state.searchKey,
    };
    let result;
    switch (e.target.name) {
      case "search":
        result = getData(data, "search");
        setState({ ...state, activeItemIndex: 0 });
        break;
      case "reset":
        setState({ ...state, searchKey: "" });
        data["username"] = "";
        result = getData(data, "reset");
        break;
      default:
    }
  };
  const changeHandler = (e) => {
    switch (e.target.name) {
      case "pos_search":
        setState({ ...state, searchKey: e.target.value });
        break;
      case "user_search":
        setState({ ...state, searchKey: e.target.value });

        break;
      default:
    }
  };
  return (
    <>
      <div className={style.title}>
        <strong>{`${t("Common.board")}-${id}`}</strong>
      </div>
      {/* <div className={style.searchBar}>
        <div className={style.searchBarWrapper}>
          <input
            type="text"
            value={state.searchKey}
            onChange={(e) => {
              changeHandler(e);
            }}
            style={{ width: "100px" }}
            name="user_search"
            placeholder={t("profile.userName")}
            className={style.searchInput}
          />
        </div>
        <div>
          <Button
            name="search"
            onClick={(e) => {
              submitHamdler(e);
            }}
            className={style.searchButton}
          >
            {t("Button.search")}
          </Button>
        </div>
        <div>
          <Button
            name="reset"
            onClick={(e) => {
              submitHamdler(e);
            }}
            className={style.searchButton}
            variant="danger"
          >
            {t("Button.reset")}
          </Button>
        </div>
      </div> */}

      <div className={style.tickets}>
        {state.loading ? (
          <Spinner />
        ) : state.nodata ? (
          <div className={`${style.NodataImage}`}>
            <center>
              <img src={nodata} alt="nodata" style={{ width: "50%" }} />
            </center>
          </div>
        ) : (
          <ItemsCarousel
            // Placeholder configurations
            enablePlaceholder={false}
            numberOfPlaceholderItems={5}
            minimumPlaceholderTime={1000}
            placeholderItem={
              <div style={{ height: 200, background: "#b8b8b8" }}>
                Loading...
              </div>
            }
            // Carousel configurations
            numberOfCards={state.numberOfCards}
            gutter={12}
            showSlither={true}
            firstAndLastGutter={true}
            freeScrolling={true}
            // Active item configurations
            requestToChangeActive={changeActiveItem}
            activeItemIndex={state.activeItemIndex}
            activePosition={"center"}
            chevronWidth={30}
            rightChevron={
              <ArrowCircleRightTwoToneIcon fontSize="large" color="action" />
            }
            leftChevron={
              <ArrowCircleLeftTwoToneIcon fontSize="large" color="action" />
            }
            outsideChevron={false}
          >
            {state.children}
          </ItemsCarousel>
        )}
      </div>
    </>
  );
};

export default Content;
