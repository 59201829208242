import TableData from "./TableData";
import { useTranslation } from "react-i18next";

import React from "react";

export default function Report1(data) {
  console.log("data 11111 ", data);
  const { t } = useTranslation();
  let columnsData = [
    {
      accessorKey: "id",
      header: "box",
      size: 10,
    },
    {
      accessorKey: "created",
      header: t("Common.date_created"),
      size: 50,
    },
    {
      accessorKey: "ticket_id",
      header: `${t("Common.ticket")} No.`,
      size: 50,
    },
    {
      accessorKey: "box.1",
      header: `${t("Common.box")} 1`,
      size: 30,
    },
    {
      accessorKey: "box.2",
      header: `${t("Common.box")} 2`,
      size: 30,
    },
    {
      accessorKey: "box.3",
      header: `${t("Common.box")} 3`,
      size: 30,
    },
    {
      accessorKey: "box.4",
      header: `${t("Common.box")} 4`,
      size: 30,
    },
    {
      accessorKey: "box.5",
      header: `${t("Common.box")} 5`,
      size: 30,
    },
    {
      accessorKey: "box.6",
      header: `${t("Common.box")} 6`,
      size: 30,
    },
    {
      accessorKey: "maturity_bonus",
      header: "Maturity Bonus",
      size: 50,
    },
    {
      accessorKey: "gift_ticket_1",
      header: `${t("Common.gift_ticket")} 2`,
      size: 150,
    },

    {
      accessorKey: "date",
      header: t("Common.date"),
      size: 50,
    },
  ];
  return data && <TableData columnsData={columnsData} data={data.data} />;
}
