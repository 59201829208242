import React from "react";
import { useMemo } from "react";
import {
  MaterialReactTable,
  useMaterialReactTable,
  MRT_GlobalFilterTextField,
  MRT_ToggleFiltersButton,
} from "material-react-table";

export default function TableData({ columnsData, data }) {
  console.log("========data 7878",data)
  const columns = useMemo(() => columnsData, []);
  const table = useMaterialReactTable({
    columns,
    data,
    enableFullScreenToggle: false,
    enableSorting:false
  });
  return <MaterialReactTable table={table} />;
}
