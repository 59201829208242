import React from "react";
import Helmet from "react-helmet";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import { useTranslation } from "react-i18next";
//components
import PageTitle from "../../components/common/pageTitle";
import Content from "../../components/ticketpurchase/Content";
//service
import companyName from "../../../store/mobxStore/companyName";

export default function TicketPurchase() {
  const { t } = useTranslation();
  return (
    <>
      <div className={`h-100`}>
        <Helmet>
          <title>
            {companyName.companyName} | {t("Sidemenu.ticket-purchase")}
          </title>
        </Helmet>
        <PageTitle title="ticket-purchase" buttonOn={false} />
        <br />
        <Content />
      </div>
    </>
  );
}
