import { useState, useEffect } from "react";
import BoardServices from "../../../service/board/board";
import Spinner from "../../shared/Spinner";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import Report1 from "./Report1";
import Report2 from "./Report2";

const ReportTable = () => {
  const params = useParams();
  const [loading, setLoading] = useState(true);
  const { id } = params;
  const [tableData, setTableData] = useState();
  const Service = new BoardServices();
  const getData = () => {
    setLoading(true);
    Service.getBoardReport(id).then((res) => {
      setLoading(false);
      setTableData(res.data);
    });
  };

  useEffect(() => {
    getData();
  }, []);
  useEffect(() => {
    getData();
  }, [id]);
  return (
    <>
      {!loading && tableData && id == 1 ? (
        <Report1 data={tableData} />
      ) : !loading && tableData && id == 2 ? (
        <Report2 data={tableData} />
      ) : (
        <Spinner />
      )}
    </>
  );
};

export default ReportTable;
