import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Payment from "./Payment";
import BoardServices from "../../../service/board/board";
import { AlertMessage } from "../common";
import style from "./ticketpurchase.module.scss";
import Spinner from "../../shared/Spinner";
import { useSelector } from "react-redux";


export default function Content() {
  const { currentCurr } = useSelector((state) => state.curr);
  console.log("currentCurr ---- ", currentCurr);
  const { t } = useTranslation();
  const Service = new BoardServices();

  const [activePayment, setActivePayment] = useState();
  const [state, setState] = useState({
    paymentMoadal: false,
    paymentMethods: {},
    board_id: "",
    ticket_id: "",
    amount: 0,
    tickets: [],
    notify: {
      show: false,
      message: "",
      header: "",
      type: "",
    },
    ewalletData: {
      username: "",
      password: "",
    },
    loading: true,
  });

  const getEwalletData = (username, password) => {
    setState((prevState) => ({
      ...prevState,
      ewalletData: {
        username: username,
        password: password,
      },
    }));
  };
  console.log("state ===== ", state);
  useEffect(() => {
    Service.getTicketsForPurchase().then((res) => {
      console.log("res=== ", res);
      setState({
        ...state,
        paymentMethods: res.data.payment_methods,
        tickets: res.data.tickets,
        loading: false
      });
      setActivePayment(res?.data?.payment_methods?.fields[0]?.code);
    });
  }, []);
  useEffect(() => {
    setState({
      ...state,
      loading: true
    });
    Service.getTicketsForPurchase().then((res) => {
      console.log("res=== ", res);
      setState({
        ...state,
        paymentMethods: res.data.payment_methods,
        tickets: res.data.tickets,
        loading: false
      });
      // setActivePayment(res.data.payment_methods.fields[0].code);
    });
  }, [state.paymentMoadal]);
  console.log("tickets=================", state.tickets);
  const notifyClose = () => {
    setState((prev) => ({
      ...prev,
      notify: {
        show: false,
        message: "",
        type: "",
      },
    }));
  };
  const submitHandler = (event,token) => {
    console.log("event", event);
    event.preventDefault();
    let data = {
      ticket_id: state.ticket_id,
      board_id: state.board_id,
      price: state.amount,
      payment_method: activePayment,
    };
    setState((prev) => ({
      ...prev,
      loading: true,
    }));
    switch (activePayment) {
      case "stripe":
        data["token"] = token;
        Service.ticketPurchase(data).then((res) => {
          console.log("submit ==== ", res);
          let notify;
          if (res.status == true) {
            notify = {
              show: true,
              message: "purchase completed",
              header: "",
              type: "success",
            };
          } else {
            notify = {
              show: true,
              message: "purchase failed",
              header: "",
              type: "danger",
            };
          }
          setState((prev) => ({
            ...prev,
            notify: notify,
            paymentMoadal: false,
            loading: false,
          }));
        });
        break;
      case "ewallet":
        data["ewallet"] = state.ewalletData;
        Service.ticketPurchase(data).then((res) => {
          console.log("submit ==== ", res);
          let notify;
          if (res.status == true) {
            notify = {
              show: true,
              message: "purchase completed",
              header: "",
              type: "success",
            };
          } else {
            notify = {
              show: true,
              message: "purchase failed",
              header: "",
              type: "danger",
            };
          }
          setState((prev) => ({
            ...prev,
            notify: notify,
            paymentMoadal: false,
            loading: false,
          }));
        });
        break;
      default:
    }
  };
  const toPayment = (price, ticket_id, board_id) => {
    setState({
      ...state,
      paymentMoadal: true,
      amount: price,
      ticket_id: ticket_id,
      board_id: board_id,
    });
  };

  const prevButtonClick = () => {
    setState({ ...state, paymentMoadal: false });
  };
  console.log("state ==== ", state);
  console.log("state ==== ", state);
  console.log("activePayment ==== ", activePayment);

  return (
    <>
      {state.notify.show && (
        <AlertMessage
          show={state.notify.show}
          message={state.notify.message}
          dismiss={notifyClose}
          type={state.notify.type}
          header={state.notify.header}
        />
      )}

      {!state.paymentMoadal && state.tickets && (
        <Row className="m-2">
          {state.tickets.map((item) => {
            return (
              <>
                <Col md={4}>
                  <Card>
                    <div className={style.image}>
                      <Card.Img variant="top" src={item?.img ?? ""} />
                    </div>
                    <Card.Body>
                      <div>
                        <strong>
                          <Card.Title>{item.title}</Card.Title>
                        </strong>
                      </div>
                      <div>
                        <Card.Text>
                          {t(`Common.price`)}: {item.price} {currentCurr}
                        </Card.Text>
                      </div>
                      <br />
                      <Button
                        variant={
                          item.text == "buy"
                            ? "primary"
                            : item.text == "not_eligible"
                              ? "secondary"
                              : "success"
                        }
                        onClick={() => {
                          toPayment(item.price, item.ticket_id, item.board_id);
                        }}
                        disabled={!item.status}
                      >
                        {t(`Common.${item.text}`)}
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              </>
            );
          })}
        </Row>
      )}
      {state.paymentMoadal && (
        <>
          <div style={{ padding: "20px" }}>
            <Payment
              payments={state.paymentMethods}
              submit={submitHandler}
              amount={state.amount}
              state={state}
              prevButtonClick={prevButtonClick}
              setActivePayment={setActivePayment}
              payment_type="ticket_purchase"
              ewallet={getEwalletData}
            />
          </div>
        </>
      )}
      {state.loading && <Spinner />}
    </>
  );
}
