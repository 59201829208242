import React from 'react'
import style from './cart.module.scss';
import {AddonNotification,AlertBs} from '../common';
import {useTranslation} from 'react-i18next';
import CheckoutWizard from './CheckoutWizard';
import {EmptyCart,AddModal} from './'

function CheckoutContent(props) {
    const {t} = useTranslation();
    return (
        <div className={`${style.checkoutContainer}`}>
            <AlertBs 
                varient={props?.alert?.varient}
                show={props?.alert?.show}
                message={props?.alert?.message}
                close={props.close}
                field={props?.alert?.field}
            />
            {/* <AddonNotification/> */}
            <legend>
                <span>{t('Common.checkoutSteps')}</span>
            </legend>
            {
                props.cart && props.cart.length > 0 ?
                    <CheckoutWizard 
                    {...props}
                    /> : 
                    <EmptyCart />
            }
            <AddModal 
                show={props?.modal?.show}
                handleClose={props?.modalToogle}
                submit={props?.addAddress}
                {...props}
            />
        </div>
    )
}

export default CheckoutContent
