import React, { useCallback, useEffect, useState } from "react";
import Content from "../../components/coaching/Content";

import { Helmet } from "react-helmet";
import companyName from "../../../store/mobxStore/companyName";
import PageTitle from "../../components/common/pageTitle";
import { useTranslation } from "react-i18next";

function Coaching() {
  const { t } = useTranslation();
  return (
    <div className="h-100">
      <Helmet>
        <title>
          {companyName.companyName} | {t("Sidemenu.coaching")}
        </title>
      </Helmet>
      <PageTitle title="coaching" buttonOn={false} />
      <div className="mt-10">
        <Content />
      </div>
    </div>
  );
}

export default Coaching;
