import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "./Checkout";
import ProfileService from "../../../service/profile";
import _ from "lodash";
import { STRIPE_PUBLIC_KEY } from "../../../configuration/apiconfig";

function Stripes(props) {
  const [stripeData, setstripeData] = useState({
    status: false,
    email: "",
    name: "",
    phone: "",
    desc: "",
    userAction: "",
    id: false,
  });
  const paymentService = new ProfileService();
  useEffect(() => {
    getUserdetails();
  }, []);
  const getUserdetails = async () => {
    if (
      window.location.pathname.includes("/register") ||
      window.location.pathname.includes("/replica_register")
    ) {
      console.log("props.conatct === ", props.conatct);
      const email = _.find(props.conatct, { code: "email" });
      const name = _.find(props.conatct, { code: "firstName" });
      const phone = _.find(props.conatct, { code: "mobile" });
      setstripeData((prev) => ({
        ...prev,
        status: true,
        name: name.value,
        email: email.value,
        phone: phone.value,
        desc: `${
          window.location.pathname.includes("/replica_register")
            ? "Replica Registration"
            : "Registration"
        }for ${email.value}`,
        userAction: window.location.pathname.includes("/replica_register")
          ? "replica_register"
          : "register",
      }));
    } else if (
      window.location.pathname.includes("/upgrade_package") ||
      window.location.pathname.includes("/upgrade_validity")
    ) {
      const response = await paymentService.getStripeusedetails();
      console.log("stripe user details === ", response);
      setstripeData((prev) => ({
        ...prev,
        status: true,
        name: response.data.name,
        email: response.data.email,
        phone: response.data.phone,
        user_id: response.data.id,
        desc: `${
          window.location.pathname.includes("/upgrade_package")
            ? "Upgrade"
            : "Renewal"
        } for ${response.data.email}`,
        userAction: window.location.pathname.includes("/upgrade_package")
          ? "upgrade_package"
          : "upgrade_validity",
      }));
    } else if (window.location.pathname.includes("/ticket-purchase")) {
      const response = await paymentService.getStripeusedetails();
      setstripeData((prev) => ({
        ...prev,
        status: true,
        name: response.data.name,
        email: response.data.email,
        phone: response.data.phone,
        user_id: response.data.id,
        desc: `ticket_purchase for ${response.data.email}`,
        userAction: "ticket_purchase",
      }));
    }
  };
  const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);
  return (
    <div className="app">
      {stripePromise && stripeData.status && (
        <Elements stripe={stripePromise}>
          <Checkout
            {...props}
            stripeData={stripeData}
            createFunctionToCall={props.createFunctionToCall}
          />
        </Elements>
      )}
    </div>
  );
}
export default Stripes;
