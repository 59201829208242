import React, { useState } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import style from "./payment.module.scss";
import Alert from "react-bootstrap/Alert";
import ReplicaRegService from "../../../service/Auth/Replicareg";
import AlertMessage from "../common/AlertMessage";
import Spinner from "../../shared/Spinner";

const Checkout = (props) => {
  const [processing, setProcessing] = useState(false);
  const [stripeError, setStripeError] = useState();
  const [stripesucess, setStripesucess] = useState(false);
  const stripe = useStripe();
  const elements = useElements();
  const replicaService = new ReplicaRegService();
  const [notify, setNotify] = useState({
    notify: {
      show: false,
      type: "",
      message: "",
      header: "",
    },
  });
  const dissmissNotify = () => {
    setNotify((prev) => ({
      ...prev,
      notify: {
        show: false,
        type: "",
        message: "",
        header: "",
      },
    }));
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    let res;
    setProcessing(true);
    setStripeError("");
    const cardElement = elements.getElement(CardElement);
    const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: "card",
      card: cardElement,
      billing_details: {
        email: props?.stripeData?.email,
        name: props?.stripeData?.name,
        phone: props?.stripeData?.phone,
      },
    });
    if (error) {
      setStripeError(error.message);
      setProcessing(false);
      return;
    }
    let stripeResponse = await replicaService.stripePaymentHandle({
      intentId: paymentMethod?.id,
      data: {
        email: props?.stripeData?.email,
        name: props?.stripeData?.name,
        desc: props?.stripeData?.desc,
        amount: props.amount,
        product: props.product,
        user_id: props.stripeData.user_id,
      },
      userAction: props?.stripeData?.userAction,
    });
    if (stripeResponse.status) {
      event.target.querySelector('button[type="submit"]').disabled = true;
      if (stripeResponse.data.requireAction) {
        const { error, paymentIntent } = await stripe.confirmCardPayment(
          stripeResponse.data.clientSecret,
          {
            payment_method: {
              card: cardElement,
            },
          }
        );
        if (error) {
          console.log(error);
          setStripeError(error.message);
        } else if (paymentIntent.status === "requires_action") {
          const { error: actionError, paymentIntent: updatedPaymentIntent } =
            await stripe.handleCardAction(paymentIntent.client_secret);
          if (actionError) {
            setStripeError(actionError.message);
          } else {
            console.log(updatedPaymentIntent);
          }
        } else {
          if (paymentIntent.status === "succeeded") {
            console.log("payment successssssssssss");
            setStripesucess(true);
            if (
              window.location.pathname.includes("/register") ||
              window.location.pathname.includes("/replica_register")
            ) {
              props.submit(stripeResponse.data, "", "payment");
            } else if (window.location.pathname.includes("/ticket-purchase")) {
              console.log("inside ticket-purchase ---------------");
              props.submit(event, stripeResponse.data);
            } else {
              console.log("else inside ticket-purchase ---------------", window.location.pathname);

              props.submit(stripeResponse.data, "");
            }
          } else {
            console.log(paymentIntent);
            setStripeError("Payment failed");
          }
        }
        setProcessing(false);
      } else {
        setStripesucess(true);
        if (
          window.location.pathname.includes("/register") ||
          window.location.pathname.includes("/replica_register")
        ) {
          props.submit(stripeResponse.data, "", "payment");
        } else if (window.location.pathname.includes("/ticket-purchase")) {
          console.log("inside ticket-purchase ---------------");
          props.submit(event, stripeResponse.data);
        } else {
          props.submit(stripeResponse.data, "");
        }
      }
    } else {
      setProcessing(false);
      setStripeError(
        res?.error?.description
          ? res?.error?.description
          : "Something went wrong.."
      );
    }
    event.target.querySelector('button[type="submit"]').disabled = false;
  };

  return (
    <>
      <div className={style.Buttons}>
        {stripeError && <Alert variant="danger">{stripeError}</Alert>}
        {stripesucess && (
          <AlertMessage
            message={"Payment Completed"}
            dismiss={dissmissNotify}
            type={"success"}
            header={"Success"}
            show={true}
          />
        )}
      </div>
      {props?.loader || !props?.stripeData?.status ? (
        <div>{props?.loader ? <Spinner /> : "Loading Stripe ..."}</div>
      ) : (
        <>
          <form onSubmit={handleSubmit} id="payment-form">
            <div id="card-element">
              <CardElement
                options={{
                  base: {
                    color: "#32325d",
                    fontFamily: "Arial, sans-serif",
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                      color: "#32325d",
                    },
                  },
                  invalid: {
                    fontFamily: "Arial, sans-serif",
                    color: "#fa755a",
                    iconColor: "#fa755a",
                  },
                }}
              />
              <button
                id="submit-new"
                type="submit"
                disabled={!stripe || processing}
                style={{ backgroundColor: "#084b60" }}
              >
                {
                  <>
                    {processing ? (
                      <div>
                        <i
                          className="fa-solid fa-spinner fa-spin"
                          style={{ color: "#ffffff" }}
                        ></i>
                        Processing...
                      </div>
                    ) : (
                      <span id="button-text">
                        {" "}
                        <i
                          className="fa-solid fa-circle-notch fa-spin"
                          style={{ color: "#ffffff" }}
                        ></i>{" "}
                        Pay now
                      </span>
                    )}
                  </>
                }
              </button>
            </div>
          </form>
        </>
      )}
    </>
  );
};
export default Checkout;
