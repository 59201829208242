import React, { useState, useEffect } from "react";
import { Card, Button, Col, Row } from "react-bootstrap";
import DashboardService from "../../../service/dashboard/Dashboard";
import nodata from "../../../assets/images/nophoto/no-datas-found.png";
import style from "./coaching.module.scss";
import { useSelector } from "react-redux";

export default function Content() {
  const { currentTheme } = useSelector((state) => state.Theme);
  const Service = new DashboardService();
  const [state, setState] = useState({
    data: [],
  });
  const getData = async () => {
    console.log("state getData triggered");
    try {
      let data = await Service.coachingData();
      setState({ ...state, data: data.data });
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);
  const openLink = (link) => {
    window.open(link, "_blank");
  };
  console.log("state", state);
  return (
    <>
      <div style={{ padding: "30px" }}>
        <Row>
          {state.data.length > 0 ? (
            state.data.map((item) => {
              return (
                // <Col lg={12} md={12} sm={12}>
                <Col lg={4} md={6} sm={12}>
                  <Card>
                    <Card.Body>
                      <Card.Title
                        style={{
                          color:
                            currentTheme == "theme-dark"
                              ? "#d0d0d0"
                              : "#343a40",
                        }}
                      >
                        <strong>{item.title}</strong>
                      </Card.Title>
                      <div
                        style={{
                          overflowY: "auto",
                          maxHeight: "100%",
                        }}
                      >
                        <Card.Text
                          style={{
                            height: "100px",
                            color:
                              currentTheme == "theme-dark"
                                ? "#d0d0d0"
                                : "#343a40",
                          }}
                        >
                          {item.desc}
                        </Card.Text>
                      </div>
                      <br />
                      <Button
                        variant="primary"
                        onClick={() => {
                          openLink(item.link);
                        }}
                      >
                        open
                      </Button>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })
          ) : (
            <div className={`${style.NodataImage}`}>
              <center>
                <img src={nodata} alt="nodata" style={{ width: "50%" }} />
              </center>
            </div>
          )}
        </Row>
      </div>
    </>
  );
}
