import PaymentMethods from "../common/PaymentMethods";
import React, { useState } from "react";

export default function Payment(props) {
  const [payment, setPayment] = useState(props.payments.fields[0]?.code);
  const paymentMethodChange = (method) => {
    setPayment(props.payments.fields[method]?.code);
    props.setActivePayment(props.payments.fields[method]?.code)
  };
  return (
    <>
      <PaymentMethods
        methods={props.payments.fields}
        submit={(e, token) => {
          props.submit(e, token, "payment");
        }}
        selectedTab={0}
        amount={props.amount}
        payment_type={"ticket"}
        paymentMethodChange={paymentMethodChange}
        {...props}
      />
    </>
  );
}
